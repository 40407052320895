import Vue from "vue";
import store from "./../store/index";
import Meta from "vue-meta";
import VueRouter from "vue-router";
import auth from "./../middleware/auth";
import admin from "./../middleware/admin";
import guest from "./../middleware/guest";
import middlewarePipeline from "./../router/middlewarePipeline";

Vue.use(Meta);
Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    meta: { middleware: [guest] },
    component: () =>
      import(
        /* webpackChunkName: "prefetch-login" */ "../views/auth/login"
        ),
  },

  {
    path: "/",
    name: "dashboard",
    meta: { middleware: [auth] },
    component: () =>
      import(
        /* webpackChunkName: "prefetch-dashboard" */ "../views/dashboard/index"
        ),
  },

  {
    path: "/notifications",
    name: "notifications",
    meta: { middleware: [auth] },
    component: () =>
      import(
        /* webpackChunkName: "prefetch-notifications" */ "../views/notifications/index"
        ),
  },

  {
    path: "/search",
    name: "search",
    meta: { middleware: [auth] },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "prefetch-search" */ "../views/search/index"
        ),
  },

  {
    path: "/ads_cars",
    name: "ads_cars",
    meta: { middleware: [auth] },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "prefetch-ads-cars" */ "../views/ads_cars/index"
        ),
  },

  {
    path: "/tasks",
    name: "tasks",
    meta: { middleware: [auth] },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "prefetch-tasks" */ "../views/tasks/index"
        ),
  },
  {
    path: "/tasks/archive",
    name: "tasks.archive",
    meta: { middleware: [auth] },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "tasks-archive" */ "../views/tasks/archive/index"
        ),
  },

  {
    path: "/task/cars",
    name: "task.cars",
    meta: { middleware: [auth] },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "prefetch-tasks" */ "../views/tasks/create/index"
        ),
  },
  {
    path: "/task/:carId/create",
    name: "task.create",
    meta: { middleware: [auth] },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "prefetch-tasks" */ "../views/tasks/create/create"
        ),
  },
  {
    path: "/task/:taskId",
    name: "task.edit",
    meta: { middleware: [auth] },
    props: true,
    component: () =>
      import(/* webpackChunkName: "task-edit" */ "../views/tasks/edit"),
  },
  {
    path: "/task/:taskId/details",
    name: "task.details",
    meta: { middleware: [auth] },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "task-details" */ "../views/tasks/details/index"
        ),
  },
  {
    path: "/task/:taskId/car_search_checklist/create",
    name: "task.car_search_checklist.create",
    meta: { middleware: [auth] },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "task-cars_search_checklists" */
        "../views/tasks/details/cars_search_checklists/create"
        ),
  },
  {
    path: "/task/:taskId/car_search_checklist/:carSearchChecklistId",
    name: "task.car_search_checklist.edit",
    meta: { middleware: [auth] },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "task-cars_search_checklists" */
        "../views/tasks/details/cars_search_checklists/edit"
        ),
  },

  {
    path: "/reports/tasks",
    name: "reports.tasks",
    meta: { middleware: [auth] },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "prefetch-tasks" */ "../views/tasks/reports/index"
        ),
  },

  {
    path: "/statistic/overall",
    name: "statistic.overall",
    meta: { middleware: [auth] },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "prefetch-statistic" */ "../views/statistic/index"
        ),
  },

  {
    path: "/statistic/incoming",
    name: "statistic.incoming",
    meta: { middleware: [auth] },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "prefetch-statistic" */ "../views/statistic/incoming"
        ),
  },

  {
    path: "/statistic/all_tasks",
    name: "statistic.all_tasks",
    meta: { middleware: [auth] },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "prefetch-statistic" */ "../views/statistic/all_tasks"
        ),
  },

  {
    path: "/statistic/chats",
    name: "statistic.chats",
    meta: { middleware: [auth] },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "prefetch-statistic" */ "../views/statistic/chats"
        ),
  },

  {
    path: "/statistic/cars_owners",
    name: "statistic.cars_owners",
    meta: { middleware: [auth] },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "prefetch-statistic" */ "../views/statistic/cars_owners"
        ),
  },

  {
    path: "/statistic/call_center",
    name: "statistic.call_center",
    meta: { middleware: [auth] },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "prefetch-statistic" */ "../views/statistic/call_center"
        ),
  },

  {
    path: "/statistic/credit_leasing",
    name: "statistic.credit_leasing",
    meta: { middleware: [auth] },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "prefetch-statistic" */ "../views/statistic/credit_leasing"
        ),
  },

  {
    path: "/statistic/exchange_buyout",
    name: "statistic.exchange_buyout",
    meta: { middleware: [auth] },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "prefetch-statistic" */ "../views/statistic/exchange_buyout"
        ),
  },

  {
    path: "/statistic/estimate_search_sale",
    name: "statistic.estimate_search_sale",
    meta: { middleware: [auth] },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "prefetch-statistic" */ "../views/statistic/estimate_search_sale"
        ),
  },

  {
    path: "/tasks/funnel/incoming",
    name: "tasks.funnel.incoming",
    meta: { middleware: [auth] },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "prefetch-tasks-funnel-incoming" */ "../views/tasks/funnels/incoming/index"
        ),
  },

  {
    path: "/tasks/funnel/main",
    name: "tasks.funnel.main",
    meta: { middleware: [auth] },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "prefetch-tasks-funnel-main" */ "../views/tasks/funnels/main/index"
        ),
  },

  {
    path: "/tasks/funnel/personal",
    name: "tasks.funnel.personal",
    meta: { middleware: [auth] },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "prefetch-tasks-funnel-personal" */ "../views/tasks/funnels/personal/index"
        ),
  },

  {
    path: "/tasks/funnel/personal_branch",
    name: "tasks.funnel.personal_branch",
    meta: { middleware: [auth] },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "prefetch-tasks-funnel-personal-branch" */ "../views/tasks/funnels/personal_branch/index"
        ),
  },

  {
    path: "/tasks/funnel/sellers",
    name: "tasks.funnel.sellers",
    meta: { middleware: [auth] },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "prefetch-tasks-sellers-center" */ "../views/tasks/funnels/sellers/index"
        ),
  },

  {
    path: "/tasks/funnel/call_center",
    name: "tasks.funnel.call_center",
    meta: { middleware: [auth] },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "prefetch-tasks-funnel-call-center" */ "../views/tasks/funnels/call_center/index"
        ),
  },

  {
    path: "/tasks/funnel/credit",
    name: "tasks.funnel.credit",
    meta: { middleware: [auth] },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "prefetch-tasks-funnel-credit" */ "../views/tasks/funnels/credit/index"
        ),
  },

  {
    path: "/tasks/funnel/exchange",
    name: "tasks.funnel.exchange",
    meta: { middleware: [auth] },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "prefetch-tasks-funnel-exchange" */ "../views/tasks/funnels/exchange/index"
        ),
  },

  {
    path: "/tasks/funnel/search",
    name: "tasks.funnel.search",
    meta: { middleware: [auth] },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "prefetch-tasks-funnel-search" */ "../views/tasks/funnels/search/index"
        ),
  },

  {
    path: "/tasks/funnel/estimate",
    name: "tasks.funnel.estimate",
    meta: { middleware: [auth] },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "prefetch-tasks-funnel-estimate" */ "../views/tasks/funnels/estimate/index"
        ),
  },

  {
    path: "/tasks/funnel/detailing",
    name: "tasks.funnel.detailing",
    meta: { middleware: [auth] },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "prefetch-tasks-funnel-detailing" */ "../views/tasks/funnels/detailing/index"
        ),
  },

  {
    path: "/cars",
    name: "cars",
    meta: { middleware: [auth] },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "prefetch-cars" */ "../views/cars/index"
        ),
  },
  {
    path: "/cars/archive",
    name: "cars.archive",
    meta: { middleware: [auth] },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "prefetch-cars-archive" */ "../views/cars/archive/index"
        ),
  },
  {
    path: "/car/create",
    name: "car.create",
    meta: { middleware: [auth] },
    props: true,
    component: () =>
      import(/* webpackChunkName: "car-create" */ "../views/cars/create"),
  },
  {
    path: "/car/:carId",
    name: "car.edit",
    meta: { middleware: [auth] },
    props: true,
    component: () =>
      import(/* webpackChunkName: "car-edit" */ "../views/cars/edit"),
  },
  {
    path: "/car/:carId/details",
    name: "car.details",
    meta: { middleware: [auth] },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "car-details" */ "../views/cars/details/index"
        ),
  },
  {
    path: "/cars/selling",
    name: "cars.selling",
    meta: { middleware: [auth] },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "cars-selling" */ "../views/cars/selling/index"
        ),
  },
  {
    path: "/cars/calendar",
    name: "cars.calendar",
    meta: { middleware: [auth] },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "cars-calendar" */ "../views/cars/calendar/index"
        ),
  },
  {
    path: "/cars/inspections",
    name: "cars.inspections",
    meta: { middleware: [auth] },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "prefetch-cars-inspections" */ "../views/cars/inspections/index"
        ),
  },
  {
    path: "/cars/inspections/:carInspectionId",
    name: "car.inspection.edit",
    meta: { middleware: [auth] },
    props: true,
    component: () =>
      import(/* webpackChunkName: "car-inspection-edit" */ "../views/cars/details/inspections/edit"),
  },

  {
    path: "/branches",
    name: "branches",
    meta: { middleware: [auth] },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "branches" */ "../views/branches/index"
        ),
  },
  {
    path: "/branch/create",
    name: "branch.create",
    meta: { middleware: [auth] },
    component: () =>
      import(
        /* webpackChunkName: "branch-create" */ "../views/branches/create"
        ),
  },
  {
    path: "/branch/:branchId",
    name: "branch.edit",
    meta: { middleware: [auth] },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "branch-edit" */ "../views/branches/edit"
        ),
  },

  {
    path: "/news",
    name: "news",
    meta: { middleware: [auth] },
    props: true,
    component: () =>
      import(/* webpackChunkName: "news" */ "../views/news/index"),
  },
  {
    path: "/news/create",
    name: "news.create",
    meta: { middleware: [auth] },
    component: () =>
      import(
        /* webpackChunkName: "news-create" */ "../views/news/create"
        ),
  },
  {
    path: "/news/:newsId",
    name: "news.edit",
    meta: { middleware: [auth] },
    props: true,
    component: () =>
      import(/* webpackChunkName: "news-edit" */ "../views/news/edit"),
  },

  {
    path: "/reviews",
    name: "reviews",
    meta: { middleware: [auth] },
    component: () =>
      import(/* webpackChunkName: "reviews" */ "../views/reviews/index"),
  },

  {
    path: "/clients",
    name: "clients",
    meta: { middleware: [auth] },
    props: true,
    component: () =>
      import(/* webpackChunkName: "clients" */ "../views/clients/index"),
  },
  {
    path: "/client/create",
    name: "client.create",
    meta: { middleware: [auth] },
    component: () =>
      import(
        /* webpackChunkName: "client-create" */ "../views/clients/create"
        ),
  },
  {
    path: "/client/:clientId",
    name: "client.edit",
    meta: { middleware: [auth] },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "client-edit" */ "../views/clients/edit"
        ),
  },
  {
    path: "/client/:clientId/details",
    name: "client.details",
    meta: { middleware: [auth] },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "client-details" */ "../views/clients/details/index"
        ),
  },

  {
    path: "/partners",
    name: "partners",
    meta: { middleware: [auth] },
    props: true,
    component: () =>
      import(/* webpackChunkName: "partners" */ "../views/partners/index"),
  },
  {
    path: "/partner/:partnerId",
    name: "partner.edit",
    meta: { middleware: [auth] },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "partner-edit" */ "../views/partners/edit"
        ),
  },

  {
    path: "/employees",
    name: "employees",
    meta: { middleware: [auth] },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "employees" */ "../views/employees/index"
        ),
  },
  {
    path: "/employee/create",
    name: "employee.create",
    meta: { middleware: [auth] },
    component: () =>
      import(
        /* webpackChunkName: "employee-create" */ "../views/employees/create"
        ),
  },
  {
    path: "/employee/:employeeId",
    name: "employee.edit",
    meta: { middleware: [auth] },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "employee-edit" */ "../views/employees/edit"
        ),
  },
  {
    path: "/employee/:employeeId/details",
    name: "employee.details",
    meta: { middleware: [auth] },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "employee-details" */ "../views/employees/details/index"
        ),
  },

  {
    path: "/employees/departments",
    name: "employees.departments",
    meta: { middleware: [admin, auth] },
    component: () =>
      import(
        /* webpackChunkName: "employees-departments" */ "../views/departments/index"
        ),
  },
  {
    path: "/employees/departments/create",
    name: "employees.departments.create",
    meta: { middleware: [auth] },
    component: () =>
      import(
        /* webpackChunkName: "employees-departments" */ "../views/departments/create"
        ),
  },
  {
    path: "/employees/departments/:employeeDepartmentId",
    name: "employees.departments.edit",
    meta: { middleware: [auth] },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "employees-departments" */ "../views/departments/edit"
        ),
  },

  {
    path: "/integrations",
    name: "integrations",
    meta: { middleware: [auth] },
    component: () =>
      import(
        /* webpackChunkName: "integrations" */ "../views/integrations/index"
        ),
  },

  {
    path: "/seo_items",
    name: "seo_items",
    meta: { middleware: [auth] },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "seo_items" */ "../views/seo_items/index"
        ),
  },
  {
    path: "/seo_item/:seoItemId",
    name: "seo_item.edit",
    meta: { middleware: [auth] },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "seo_item.edit" */ "../views/seo_items/edit"
        ),
  },

  {
    path: "/logs",
    name: "logs",
    meta: { middleware: [auth] },
    props: true,
    component: () =>
      import(/* webpackChunkName: "logs" */ "../views/logs/index"),
  },

  {
    path: "/translations",
    name: "translations",
    meta: { middleware: [auth] },
    component: () =>
      import(
        /* webpackChunkName: "translations" */ "../views/translations/index"
        ),
  },

  {
    path: "/employees/accesses",
    name: "employees.accesses",
    meta: { middleware: [admin, auth] },
    component: () =>
      import(
        /* webpackChunkName: "employees-accesses" */ "../views/accesses/index"
        ),
  },

  {
    path: "/finances",
    name: "finances",
    meta: { middleware: [auth] },
    component: () =>
      import(
        /* webpackChunkName: "finances" */ "../views/finances/index"
        ),
  },

  {
    path: "/500",
    name: "server_error",
    meta: { middleware: [auth] },
    component: () =>
      import(
        /* webpackChunkName: "server_error" */ "../views/errors/500"
        ),
  },

  {
    path: "/:catchAll(.*)",
    name: "not_found",
    meta: { middleware: [auth] },
    component: () =>
      import(/* webpackChunkName: "not-found" */ "../views/errors/404"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  const middleware = to.meta.middleware;
  const context = { to, from, next, store };

  if (!middleware) {
    return next();
  }

  middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  });
});

export default router;
